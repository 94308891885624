.checkbox-wrap {
    position: relative;
    min-height: 28px;
    &.is-checked {
        .label {
            /* border-color: var(--dark-color);
      background-color: var(--dark-color); */
            border-color: #242424;
            background-color: #242424;
            color: #fff;
        }
        i {
            color: #fff;
        }
    }
    .label {
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        background-color: #fff;
        /* border: 1px solid var(--gray-color); */
        border: 1px solid #9c9c9c;
    }
    i {
        font-size: 14px;
        /* color: var(--gray-color); */
        color: #9c9c9c;
    }
    .checkbox {
        position: absolute;
        right: 9999px;
    }
}
