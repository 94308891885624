.phone-confirm {
    display: flex;
    position: relative;
    align-items: flex-end;
    > div {
        flex: 1;
    }
    .phone-confirm-button {
        flex: none;
        width: 127px;
        margin-left: 10px;
    }
    .form-group {
        margin-bottom: 0;
    }
    .input {
        height: 50px;
        border-radius: 0;
        border-style: solid;
        -webkit-appearance: none;
        box-shadow: none !important;
    }
    .btn {
        height: 50px;
        padding: 0;
    }
    .countdown {
        position: absolute;
        top: 25px;
        right: 0;
        font-size: 12px;
        color: #000;
    }
    .phone-input {
        position: relative;
        .validation-error {
            top: 10px;
            right: -135px;
        }
    }
    .dropdown-container {
        width: auto;
        margin-left: 0;
    }
}
