.chat-group {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
}

.chat-container {
    display: flex;
    flex-direction: column;
    position: relative;
    /* width: 50%;
    height: 50%; */
    width: 500px;
    height: 600px;
    margin: 0 10px;
    /* height: 280px; */
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;

    .header {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #fff;
        width: 100%;
        padding: 20px 0;
        background-color: #242424;

        .fa-times {
            position: absolute;
            right: 20px;
            font-size: 20px;
            cursor: pointer;
        }
    }

    .chat-body {
        background-color: #f4f3f0;
        padding: 10px 20px 20px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        white-space: pre-line;
        word-break: break-all;
        /* letter-spacing: -0.6px; */
        /* line-height: 18px; */
        -webkit-overflow-scrolling: touch;

        .chat-received-body {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 15px 0;
            .chat-zikto {
                width: 35px;
                margin-bottom: 0px;
                /* border: 0.1px solid #9c9c9c; */
                border-radius: 50%;
                background: #fff;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 35px;
                margin-right: 10px;

                img {
                    width: 70%;
                }
            }

            .chat-received {
                text-align: left;
                background-color: #fff;
                padding: 15px 10px;
                /* border: 1px solid #9c9c9c; */
                border-radius: 0px 5px 5px 5px;
                width: fit-content;
                max-width: 65%;

                p {
                    text-align: left;
                }
                .btn-area {
                    display: flex;
                    flex-direction: row;
                }
            }

            .chat-loader {
                border-radius: 10px;
                width: 20px;
                height: 20px;
            }
            .chat-loader {
                /* margin: 60px auto; */
                font-size: 12px;
                position: relative;
                text-indent: -9999em;
                /* border-top: 1.1em solid rgba(0, 0, 0, .2);
  border-right: 1.1em solid rgba(0, 0, 0, .2);
  border-bottom: 1.1em solid rgba(0, 0, 0, .2);
  border-left: 1.1em solid #000; */
                border: 4px solid rgba(0, 0, 0, 0.2);
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                -webkit-animation: load8 1.5s infinite linear;
                animation: load8 1.5s infinite linear;
            }
            .chat-loader::after {
                content: '';
                position: absolute;
                top: -4px;
                left: 0px;
                border-radius: 50%;
                background-color: #000;
                width: 4px;
                height: 4px;
            }
            @-webkit-keyframes load8 {
                0% {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }
            @keyframes load8 {
                0% {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }
        }

        .chat-sent-body {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            margin: 15px 0;

            .chat-sent {
                text-align: right;
                background-color: #242424;
                border: 1px solid #242424;
                border-radius: 5px 5px 0px 5px;
                padding: 15px 10px;
                color: #fff;
                width: fit-content;
                max-width: 65%;

                p {
                    text-align: left;
                }
            }
        }
    }
    .chat-send-input {
        width: 100%;
        background: white;
        padding: 20px;

        .chat-send-input-btn {
            display: flex;
        }

        input {
            width: 100%;
            padding: 15px;
            border: 1px solid #9c9c9c;
            border-radius: 5px;
        }
        input[type='submit'] {
            background: #242424;
            color: #fff;
            border: none;
            font-weight: 600;
            flex: 0.2;
        }
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .chat-group {
        justify-content: center;
        align-items: flex-start;
    }
    .chat-container {
        margin-top: 20px;
        height: 80vh;
    }
    .chat-send-input {
        width: 100%;
        background: white;
        padding: 20px;

        .chat-send-input-btn {
            display: flex;
        }

        input {
            width: 100%;
            padding: 15px;
            border: 1px solid #9c9c9c;
            border-radius: 5px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        input[type='submit'] {
            background: #242424;
            color: #fff;
            border: none;
            font-weight: 600;
            flex: 0.2;
        }
    }
}
