.h1 {
    position: absolute;
    top: 40px;
    left: 40px;
    width: 232px;
    height: 24px;
    background: url(/imgs/common/logo.png);
    background-size: 232px 24px;
    z-index: 200;
}

.light {
    .h1 {
        background-image: url(/imgs/common/logo-light.png);
    }
    .home-utils i {
        color: #fff;
    }
    .i-bar {
        background-color: #fff;
    }
}

.dark-light {
    .home-utils i {
        color: #fff;
    }
    .i-bar {
        background-color: #fff;
    }
}
.is-nav-open {
    .home-utils {
        i {
            color: #fff !important;
        }
    }
    .i-bar {
        background-color: #000;
    }
    .h1 {
        background-image: url(/imgs/common/logo.png);
    }
}

.home-utils {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 100;
    .btn {
        padding: 20px;
        font-size: 30px;
        vertical-align: middle;
    }
}

.i-bar {
    display: block;
    width: 25px;
    height: 2px;
    margin: 8px 0;
    background-color: #000;
    transition: 0.4s;
}

.is-open {
    .i-bar:nth-child(1) {
        transform: rotate(-45deg) translate(-7px, 6px);
        background-color: #fff;
    }
    .i-bar:nth-child(2) {
        opacity: 0;
    }
    .i-bar:nth-child(3) {
        transform: rotate(45deg) translate(-7px, -7px);
        background-color: #fff;
    }
}

.nav-wrap {
    display: none;
    background-color: #fff;
    z-index: 10;
    .navs {
        display: flex;
        /* flex: 1.4; */
        width: 49%;
        justify-content: flex-end;
        background-color: #fff;
    }
    .infos {
        display: flex;
        /* flex: 1; */
        width: 644px;
        justify-content: center;
        align-items: center;
        background-color: #f4f3f0;
    }
    .info {
        text-align: center;
    }
    &.is-open {
        display: flex;
        background-color: #242424;
    }
}

.nav {
    display: flex;
    width: 650px;
    flex-direction: column;
    justify-content: center;
}

.nav-link {
    display: block;
    position: relative;
    margin: 30px 50px;
    padding-left: 25px;
    font-size: 40px;
    transition: 0.4s;
    font-weight: normal;
    &:hover {
        font-weight: 600;
        transform: scale(1.02);
    }
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 2px;
        height: 50px;
        background-color: #000;
        transform: translateY(-50%);
    }
}

.info-mail {
    font-size: 20px;
}

.info-copyright {
    font-size: 12px;
    font-family: 'Spoqa Han Sans Light';
    line-height: 1.5;
    color: #9c9c9c;
    span:nth-child(2) {
        padding: 0 15px;
    }
}

.info-links {
    margin: 20px 0 20px;
    > a {
        display: inline-block;
        padding: 10px 30px;
        vertical-align: middle;
        box-sizing: content-box;
    }
}

.info-link-zw {
    width: 50px;
    /* background: url(/imgs/common/logo-zw.png) no-repeat center top; */
}

.info-link-tc {
    width: 50px;
    /* background: url(/imgs/common/logo-tc.png) no-repeat center top; */
}

.info-link-um {
    width: 50px;
    /* background: url(/imgs/common/logo-tc.png) no-repeat center top; */
}

.info-socials {
    font-size: 20px;
    margin-bottom: 20px;
    > a {
        padding: 10px 15px;
        font-weight: normal;
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .light,
    .dark-light {
        position: relative;
        width: 100%;
        height: 60px;
        border-bottom: 1px solid;
        background: #fff;
    }
    .h1 {
        top: 20px;
        left: 20px;
        width: 187px;
        height: 24px;
        background-size: 187px 20px;
        background-repeat: no-repeat;
    }
    .home-utils {
        top: 0px;
        right: 5px;

        .btn {
            padding: 15px;
            font-size: 22px;
        }
    }
    .nav-link {
        margin: 30px 2px;
    }
    .i-bar {
        display: block;
        width: 22px;
        height: 2px;
        margin: 6px 0;
    }
    .nav-wrap {
        .navs {
            flex: 1;
            width: 50%;
        }
        .infos {
            flex: 1;
            width: 50%;
        }
    }
    .nav {
        width: 100%;
        padding: 0 40px;
    }
    .is-nav-open {
        .home-utils {
            i {
                color: #000 !important;
            }
        }
    }
    .is-open {
        .i-bar:nth-child(1) {
            transform: rotate(-45deg) translate(-4px, 6px);
            background-color: #000;
        }
        .i-bar:nth-child(2) {
            opacity: 0;
        }
        .i-bar:nth-child(3) {
            transform: rotate(45deg) translate(-5px, -8px);
            background-color: #000;
        }
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .light,
    .dark-light {
        /*  */
        position: fixed;
        z-index: 1;
        /*  */
        width: 100%;
        height: 63px;
        border-bottom: 1px solid;
        .h1 {
            top: 20px;
            left: 20px;
            width: 187px;
            height: 24px;
            background-size: 187px 20px;
            background-repeat: no-repeat;
        }
        .home-utils {
            top: 0px;
            right: 5px;
            .btn {
                padding: 15px;
                font-size: 22px;
            }
        }
        .i-bar {
            display: block;
            width: 22px;
            height: 2px;
            margin: 6px 0;
        }

        .is-open {
            background: #fff;
            .i-bar:nth-child(1) {
                transform: rotate(-45deg) translate(-4px, 6px);
                background-color: #000;
            }
            .i-bar:nth-child(2) {
                opacity: 0;
            }
            .i-bar:nth-child(3) {
                transform: rotate(45deg) translate(-5px, -8px);
                background-color: #000;
            }
        }
        .container-fixed {
            flex-direction: column;
        }
        .nav {
            width: 100%;
            padding-left: 25px;
        }
        .nav-link {
            margin: 20px 0px;
            font-size: 24px;
            &::before {
                height: 30px;
            }
        }
        .nav-wrap {
            .navs {
                flex: 1.5;
            }
            .infos {
                display: flex;
                flex: 0.5;
                justify-content: flex-start;
                padding: 0 20px;
            }
            .info {
                text-align: left;
            }
            &.is-open {
                /* display: flex; */
            }
        }
        .info-links {
            margin: 20px 0;
            > a {
                padding: 0 10px;
                text-align: center;
            }
            /* > a:last-child {
                padding-left: 20px;
            } */
        }
        .info-link-zw {
            width: 50px;
        }

        .info-link-tc {
            width: 50px;
        }

        .info-link-um {
            width: 50px;
        }

        .info-top {
            display: flex;
            justify-content: space-between;
        }
        .info-socials {
            margin-bottom: 0;
            > a {
                font-size: 20px;
                padding: 10px 20px 0 0;
            }
        }
        .info-mail {
            font-size: 14px;
            font-weight: 600;
            line-height: 23px;
        }
    }
    .is-nav-open {
        .home-utils {
            i {
                color: #000 !important;
            }
        }
    }
}
