.tab-list {
    display: flex;
    margin: 0 0 25px;
    padding: 0;
}

.tab-list-item {
    display: flex;
    position: relative;
    flex: 1;
    height: 50px;
    list-style: none;
    margin-left: 5px;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    /* border: 1px solid var(--gray-color);
    color: var(--gray-color); */
    border: 1px solid #9c9c9c;
    color: #9c9c9c;
    cursor: pointer;
    &:first-child {
        margin-left: 0;
    }
    &::after {
        display: none;
        content: '';
        position: absolute;
        bottom: -5px;
        left: 50%;
        width: 10px;
        height: 5px;
        border-top: solid 5px #242424;
        border-left: solid 5px transparent;
        border-right: solid 5px transparent;
    }
}

.tab-list-active {
    /* background-color: var(--dark-color); */
    /* border-color: var(--dark-color); */
    background-color: #242424;
    border-color: #242424;
    color: #fff;
    &::after {
        display: block;
    }
}
