.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.image-btn {
    border: 1px solid #fff;
    color: #fff;
    background-color: transparent;
    padding: 1.2em 0.5em;
    font-weight: bold;
    width: 100%;
}

.upload-btn-wrapper input[type='file'] {
    font-size: 100px;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.preview {
    margin-top: 20px;
    border: 1px solid #fff;
    padding: 5px;
}
